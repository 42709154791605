<template>
  <Page color="info">
    <template slot="title">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            {{ title }}
            <v-btn icon>
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </span>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in courses"
            :key="index"
            :to="{
              name: $route.name,
              params: { id: item.id },
              query: { search: search },
            }"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-list-item-subtitle>{{
                formatDatespan(item.startDate, item.endDate)
              }}</v-list-item-subtitle>
              {{ item.teachers.map((item) => item.code).join(", ") }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template slot="icons" v-if="course.id && !$vuetify.breakpoint.xs">
      <span>{{ formatDatespan(course.startDate, course.endDate) }}</span>
      ,
      <span class="ml-1">{{
        loading ? "" : course.teachers.map((item) => item.code).join(", ")
      }}</span>
      ,
      <span class="ml-1"
        >{{ course.studentCount.total }} Schüler:innen ({{
          course.studentCount.female
        }}
        <v-icon small>mdi-gender-female</v-icon>,
        {{ course.studentCount.male }}
        <v-icon small>mdi-gender-male</v-icon>
        <span v-if="course.studentCount.fluid > 0"
          >,
          {{ course.studentCount.fluid }}
          <v-icon small>mdi-gender-transgender</v-icon></span
        >)
      </span>
    </template>
    <template slot="extension">
      <v-tabs background-color="info darken-1" rounded>
        <v-btn icon :to="{ name: 'Courses' }" exact
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <v-tab :to="{ name: 'CourseList' }">Schüler:innen</v-tab>

        <v-tab :to="{ name: 'CourseRegister' }">Kursbuch</v-tab>
        <v-tab v-if="!$_hasRole('student')" :to="{ name: 'CourseDocuments' }"
          >Dokumente</v-tab
        >
        <v-tab v-if="!$_hasRole('student')" :to="{ name: 'CourseDice' }"
          >Würfel</v-tab
        >
        <v-tab v-if="!$_hasRole('student')" :to="{ name: 'CourseFlipper' }"
          >Flipper</v-tab
        >
      </v-tabs>
    </template>
    <router-view
      :course="course"
      :studentGroups="studentGroups"
      @updated="fetchData"
    ></router-view>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import { formatDatespan } from "common/utils/date.js";
import { sortPeople } from "common/utils/people.js";

export default defineComponent({
  name: "Course",
  props: { id: Number },
  data() {
    return {
      loading: true,
      search: "",
      course: {},
      courses: [],
      studentGroups: [],
    };
  },
  computed: {
    title() {
      if (this.loading) {
        return "Kurs";
      }
      return this.course.title;
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
    async search() {
      this.courses = await this.apiList({
        resource: "course/course",
        query: `search=${this.search}&startDate=${this.$_currentTerm.startDate}&endDate=${this.$_currentTerm.endDate}`,
      }).courses;
    },
  },
  methods: {
    formatDatespan,
    async fetchData() {
      if (!this.id) {
        return;
      }
      this.loading = true;
      this.course = await this.apiGet({
        resource: "course/course",
        id: this.id,
      });

      this.course.students = sortPeople(this.course.students);
      this.studentGroups = await this.apiGet({
        resource: "course/coursegroup",
        id: this.id,
      });

      if (this.$_hasRole("staff")) {
        this.search = this.$route.query.search;
        this.courses = await this.apiList({
          resource: "course/course",
          query: `search=${this.search}&startDate=${this.$_currentTerm.startDate}&endDate=${this.$_currentTerm.endDate}`,
        }).courses;
      }
      if (!this.courses || this.courses.length == 0) {
        this.courses = await this.apiList({
          resource: "course/course",
          query: `person=${this.$_profilePerson.id}&startDate=${this.$_currentTerm.startDate}&endDate=${this.$_currentTerm.endDate}`,
        });
        //this.course = this.courses.find((el) => el.id == this.id);
      }
      this.loading = false;
    },
  },
  async created() {
    await this.fetchData();
  },
});
</script>
